/* purgecss start ignore */
@tailwind base;

body {
  overflow: hidden;
}

header {
  @apply block;
}

h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
  @apply font-bold;
  @apply text-gray-800;
}
a {
  @apply text-blue-600;
}

@tailwind components;
/* purgecss end ignore */

@tailwind utilities;
